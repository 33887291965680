import { latLng, LatLngBounds, latLngBounds } from 'leaflet';
import { inRange } from 'lodash-es';
import { DrillholeCoordinates } from 'state-domains/domain';

import { MapItemProps, WrappedLongitudeDict } from '.';

export const ZOOM_LEVEL = {
    JUMP: 5, // When "jumping" to a marker
    MIN: 2, // Also used as the default when showing the map for the first time.
    MED: 7, // The entire South Island is visible if the location is Castle Hill
    MAX: 18, // default max zoom for leaflet
};

export const MAX_BOUNDS = latLngBounds(latLng(90, -Infinity), latLng(-90, Infinity)); // prevent user scrolling past top and bottom of world

export const MAP_ANIMATION_DURATION = 1; // seconds

// Checks if a value is a string, and if it is, whether it can be converted to a number, if it can be converted
// the converted value is returned, otherwise the original value passed to the function is returned.
export function convertToNumberIfValid(valueToConvert: number | string) {
    try {
        const retval = Number(valueToConvert);
        return isNaN(retval) ? valueToConvert : retval;
    } catch (_error) {
        return valueToConvert;
    }
}

export function isLatitude(lat: number | string): boolean {
    const convertedLat = convertToNumberIfValid(lat);
    return typeof convertedLat === 'string'
        ? false
        : isFinite(convertedLat) && Math.abs(convertedLat) <= 90;
}

export function isLongitude(lng: number | string): boolean {
    const convertedLng = convertToNumberIfValid(lng);
    return typeof convertedLng === 'string'
        ? false
        : isFinite(convertedLng) && Math.abs(convertedLng) <= 180;
}

export function validPosition(
    latitude: number | string | undefined,
    longitude: number | string | undefined,
): boolean {
    return (
        longitude !== undefined &&
        latitude !== undefined &&
        isLatitude(latitude) &&
        isLongitude(longitude)
    );
}

export function drillholeHasValidPosition({ northing, easting }: DrillholeCoordinates): boolean {
    return validPosition(northing, easting);
}

export function findDrillholeCoordinatesBounds(drillholesWithLatLng: MapItemProps[]): LatLngBounds {
    // this function expects a list of drillholes that has been pre-filterd for valid lat, lng values
    // thus we never actually expect them to be undefined, this is just to make typescript happy
    if (drillholesWithLatLng.length > 0) {
        let minLat = drillholesWithLatLng[0].wgs84.northing || 0;
        let minLng = drillholesWithLatLng[0].wgs84.easting || 0;
        let maxLat = drillholesWithLatLng[0].wgs84.northing || 0;
        let maxLng = drillholesWithLatLng[0].wgs84.easting || 0;

        drillholesWithLatLng.forEach((drillhole: MapItemProps) => {
            minLat = Math.min(minLat, drillhole.wgs84.northing || 0);
            minLng = Math.min(minLng, drillhole.wgs84.easting || 0);
            maxLat = Math.max(maxLat, drillhole.wgs84.northing || 0);
            maxLng = Math.max(maxLng, drillhole.wgs84.easting || 0);
        });
        // +.3 to maxLat to pad for marker pin visibility
        // latLngBounds.pad() didn't work in this case
        return latLngBounds(latLng(minLat, minLng), latLng(maxLat + 0.3, maxLng));
    }
    return latLngBounds(latLng(90, -180), latLng(-90, 180));
}

export function getBoundingBoxGeoJSONQuadrants(bounds: LatLngBounds) {
    let north = bounds.getNorth();
    let south = bounds.getSouth();
    let east = bounds.getEast();
    let west = bounds.getWest();

    if (north > 90) north = 90;
    if (south < -90) south = -90;
    if (east > 180) east = 180;
    if (west < -180 || west > 180) west = -180;

    return [
        [east, north],
        [west, north],
        [west, south],
        [east, south],
        [east, north],
    ];
}

export function convertLatLngBoundsToMapItems(bounds: LatLngBounds) {
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const east = bounds.getEast();
    const west = bounds.getWest();
    return [
        { wgs84: { easting: east, northing: north } } as MapItemProps,
        { wgs84: { easting: west, northing: north } } as MapItemProps,
        { wgs84: { easting: east, northing: south } } as MapItemProps,
        { wgs84: { easting: west, northing: south } } as MapItemProps,
    ];
}
/**
 * Create a mapping so that markers on a map are wihtin the bounds of what the map displays.
 *
 * ie. If a marker id=2 lng= -125 (California), and the map is displaying bounds from 0 to 360 (London around the world and back to London)
 * Then Leaflet would not display this marker, unless we 'wrap' it around to 235.
 * This would return {2: 235,} see MapUtils.test for more examples
 *
 * @param bounds What the map 'sees'
 * @param projectsWithLatLng List of projects with lat lngs. That we want to wrap, to within the bounds if possible
 * @returns A mapping of {projectIDs : longitudes} that will be visibly correct and visible by the map
 */
export function WrapMarkers(
    bounds: LatLngBounds,
    projectsWithLatLng: MapItemProps[],
): WrappedLongitudeDict {
    const newWrappedLongitudes: WrappedLongitudeDict = {};
    const range: [number, number] = [bounds.getWest(), bounds.getEast()];
    projectsWithLatLng.forEach((project: MapItemProps) => {
        if (project.wgs84.easting) {
            let newlong = project.wgs84.easting;
            // only change project location if doing so would make it visible
            if (!inRange(project.wgs84.easting, ...range)) {
                if (inRange(project.wgs84.easting + 360, ...range)) {
                    newlong = project.wgs84.easting + 360;
                } else if (inRange(project.wgs84.easting - 360, ...range)) {
                    newlong = project.wgs84.easting - 360;
                }
            }
            newWrappedLongitudes[project.id] = newlong;
        }
    });
    return newWrappedLongitudes;
}

export function convertToMapCoordinates(projectBoundaryCoordinates: number[][]) {
    return projectBoundaryCoordinates.map((x) => [x[1], x[0]]);
}

// renderToString(button) doesn't work in this case, so we need to have the html hardcoded
export const EXPAND_MAP_ICON = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-17ceore-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ZoomOutMapIcon"><path d="m15 3 2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path></svg>`;
// Boundary.grey.svg
export const TOGGLE_BOUNDARY_OFF_ICON = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Projects" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Project-Overview-1" transform="translate(-1363.000000, -326.000000)">
            <g id="Group-2" transform="translate(1363.000000, 326.000000)">
                <rect id="Rectangle" stroke="#C7C5C0" stroke-width="2" fill="#FFFFFF" x="1" y="1" width="42" height="42" rx="4"></rect>
                <polygon id="Path-19" stroke="#A7A7A7" points="11.25 31.6836735 16.875 12.3979592 33.75 22.0408163 29.0292673 28.9285714"></polygon>
                <ellipse id="Oval" stroke="#A7A7A7" fill="#FFFFFF" cx="17.34375" cy="12.3979592" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#A7A7A7" fill="#FFFFFF" cx="34.21875" cy="21.5816327" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#A7A7A7" fill="#FFFFFF" cx="10.78125" cy="31.6836735" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#A7A7A7" fill="#FFFFFF" cx="29.53125" cy="28.9285714" rx="1.84375" ry="1.79591837"></ellipse>
            </g>
        </g>
    </g>
</svg>`;
// Boundary.svg
export const TOGGLE_BOUNDARY_ON_ICON = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Projects" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Project-Overview-1" transform="translate(-1310.000000, -326.000000)">
            <g id="Group-2" transform="translate(1310.000000, 326.000000)">
                <rect id="Rectangle" stroke="#C7C5C0" stroke-width="2" fill="#FFFFFF" x="1" y="1" width="42" height="42" rx="4"></rect>
                <polygon id="Path-19" stroke="#265C7F" points="11.25 31.6836735 16.875 12.3979592 33.75 22.0408163 29.0292673 28.9285714"></polygon>
                <ellipse id="Oval" stroke="#265C7F" fill="#FFFFFF" cx="17.34375" cy="12.3979592" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#265C7F" fill="#FFFFFF" cx="34.21875" cy="21.5816327" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#265C7F" fill="#FFFFFF" cx="10.78125" cy="31.6836735" rx="1.84375" ry="1.79591837"></ellipse>
                <ellipse id="Oval" stroke="#265C7F" fill="#FFFFFF" cx="29.53125" cy="28.9285714" rx="1.84375" ry="1.79591837"></ellipse>
            </g>
        </g>
    </g>`;
// Bullseye.svg
export const TOGGLE_LABEL_ON_ICON = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="30px" height="30px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Data-Entry" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Sample-&amp;-Duplicate" transform="translate(-288.000000, -674.000000)">
            <g id="Bullseye" transform="translate(288.000000, 674.000000)">
                <circle id="Oval" stroke="#244C68" cx="10" cy="10" r="9.5"></circle>
                <circle id="Oval" stroke="#244C68" cx="10" cy="10" r="5.5"></circle>
                <circle id="Oval" fill="#244C68" cx="10" cy="10" r="2"></circle>
            </g>
        </g>
    </g>
</svg>`;
// Modified version of Bullseye.svg
export const TOGGLE_LABEL_OFF_ICON = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="30px" height="30px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Data-Entry" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Sample-&amp;-Duplicate" transform="translate(-288.000000, -674.000000)">
            <g id="Bullseye" transform="translate(288.000000, 674.000000)">
                <circle id="Oval" stroke="#A7A7A7" cx="10" cy="10" r="9.5"></circle>
                <circle id="Oval" stroke="#A7A7A7" cx="10" cy="10" r="5.5"></circle>
                <circle id="Oval" fill="#A7A7A7" cx="10" cy="10" r="2"></circle>
            </g>
        </g>
    </g>
</svg>`;
